import React, { useEffect } from 'react';
import './App.css';

function App() {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = 'https://store.megamod.io';
    }, 1000); // Redirect after 5 seconds

    return () => clearTimeout(timer);
  }, []);

  return (
      <div className="App">
        <header className="App-header">
          <p>
            Redirecting...
          </p>
        </header>
      </div>
  );
}

export default App;
